// CSS
require('bulma')
require('@fortawesome/fontawesome-free/css/all.css')

const UTM = require('utm')
import * as turf from '@turf/turf'

// Audio
import { Howl, Howler } from 'howler'

// Geolocation API
var positionOptions = {
  enableHighAccuracy: true
}
function geolocationError(error) {
  console.log(error)
}

var watchID = navigator.geolocation.watchPosition(function(position) {
  app.geolocation = position // save position data to Vue instance
}, geolocationError, positionOptions);

// Vue.js
var Vue = require('./lib/vue')

var app = new Vue({
  el: '#app',
  data: {
    geolocation: { // fill with empty data to avoid undefined errors on computed properties
      coords: {
        accuracy: 0,
        altitude: 0,
        altitudeAccuracy: 0,
        heading: 0,
        latitude: 0,
        longitude: 0,
        speed: 0
      },
      timestamp: 0
    },
    currentTime: new Date(),
    targetElevation: 0,
    targetElevationCorrection: Number(localStorage.getItem('targetElevationCorrection')) || 0,
    altitudeCorrection: Number(localStorage.getItem('altitudeCorrection')) || 0,
    targetEasting: localStorage.getItem('targetEasting') || 1234,
    targetNorthing: localStorage.getItem('targetNorthing') || 5678,
    dropDistance: 0,
    windreader: {},
    recordedSpeeds: [],
    recordedHeadings: [],
  },
  computed: {
    utmCoordinates: function () {
      return UTM.fromLatLon(this.geolocation.coords.latitude, this.geolocation.coords.longitude)
    },
    currentPosition: function () {
      return `${this.utmCoordinates.zoneNum}${this.utmCoordinates.zoneLetter} ${Math.round(this.utmCoordinates.easting)} / ${Math.round(this.utmCoordinates.northing)}`
    },
    targetZone: function () {
      return `${this.utmCoordinates.zoneNum}${this.utmCoordinates.zoneLetter}`
    },
    geolocationLastUpdate: function () {
      var lastUpdate = new Date(this.geolocation.timestamp)
      return `${lastUpdate.toLocaleTimeString()} (${Math.round((this.currentTime - lastUpdate) / 1000)} seconds ago)`
    },
    activeGPS: function () {
      // how many seconds since last geo coordinates have been received
      var lastUpdate = new Date(this.geolocation.timestamp)
      var seconds =  Math.round((this.currentTime - lastUpdate) / 1000)
      if (seconds < 5) {
        return true
      } else {
        return false
      }
    },
    pointForward() {
      // find point which is in front of current position along current heading
      // convert degrees to radians and perform polar to cartesian conversion: degrees * (Pi / 180) * distance
      var x = this.geolocation.coords.longitude + Math.sin(this.avgHeading * (Math.PI / 180)) * 0.1
      var y = this.geolocation.coords.latitude + Math.cos(this.avgHeading * (Math.PI / 180)) * 0.1
      
      // [x, y] = lonLat
      // [y, x] = latLon
      return [x,y]
    },
    lineForward() {
      return turf.lineString([
        [this.geolocation.coords.longitude, this.geolocation.coords.latitude],
        this.pointForward
      ]);
    },
    targetUTMCoordinates() {
      if (this.targetEasting.length == 4 && this.targetNorthing.length == 4) {
        var partEasting = Math.floor(this.utmCoordinates.easting).toString().slice(-5, -1)
        var partNorthing = Math.floor(this.utmCoordinates.northing).toString().slice(-5, -1)
        var replacedEasting = Math.floor(this.utmCoordinates.easting).toString().replace(partEasting, this.targetEasting).replace(/.$/, "0")
        var replacedNorthing = Math.floor(this.utmCoordinates.northing).toString().replace(partNorthing, this.targetNorthing).replace(/.$/, "0")

        // save current target coordinates, so they will stay after page refresh
        localStorage.setItem('targetEasting', this.targetEasting)
        localStorage.setItem('targetNorthing', this.targetNorthing)

        return {
          easting: replacedEasting,
          northing: replacedNorthing,
          zoneNum: this.utmCoordinates.zoneNum,
          zoneLetter: this.utmCoordinates.zoneLetter
        }
      } else {
        // return dummy data
        return {
          easting: 888888,
          northing: 8888888,
          zoneNum: this.utmCoordinates.zoneNum,
          zoneLetter: this.utmCoordinates.zoneLetter
        }
      }
    },
    targetLatLonCoordinates() {
      return UTM.toLatLon(this.targetUTMCoordinates.easting, this.targetUTMCoordinates.northing, this.targetUTMCoordinates.zoneNum, this.targetUTMCoordinates.zoneLetter )
    },
    angleToTarget() {
      var bearing = turf.bearing([this.geolocation.coords.longitude, this.geolocation.coords.latitude], [this.targetLatLonCoordinates.longitude, this.targetLatLonCoordinates.latitude])

      return bearing - this.avgHeading
    },
    bearingToTarget() {
      var bearing = turf.bearing([this.geolocation.coords.longitude, this.geolocation.coords.latitude], [this.targetLatLonCoordinates.longitude, this.targetLatLonCoordinates.latitude], { final: true })

      return bearing
    },
    distanceToNearestPointToTarget() {
      return this.nearestPointOnLine.properties.location
    },
    distanceToTarget() {
      return turf.distance([this.geolocation.coords.longitude, this.geolocation.coords.latitude], [this.targetLatLonCoordinates.longitude, this.targetLatLonCoordinates.latitude], { units: "metres" })
    },
    whenToDrop() {
      var dropAltitude = this.altitudeAfterCorrection - this.targetElevationAfterCorrection // altitude above target
      var dropTime = dropAltitude / 10 // marker drop speed is 10m/s
      this.dropDistance = dropTime * this.avgSpeed // distance traveled by marker during drop
    
      var distanceToDrop = this.distanceToNearestPointToTarget - this.dropDistance

      return Math.max(0, distanceToDrop) // do not go below 0m
    },
    descentSpeed() {
      var dropAltitude = this.altitudeAfterCorrection - this.targetElevationAfterCorrection // altitude above target
      var speed = this.avgSpeed
      var distance = this.distanceToNearestPointToTarget

      var secondsToTarget = distance / speed // how long until target is reached

      return dropAltitude / secondsToTarget // how fast to descend to reach target on its altitude
    },
    nearestPointOnLine() {
      return turf.nearestPointOnLine(this.lineForward, [this.targetLatLonCoordinates.longitude, this.targetLatLonCoordinates.latitude], { units: 'metres' })
    },
    bestDropResult() {
      return turf.distance(this.nearestPointOnLine, [this.targetLatLonCoordinates.longitude, this.targetLatLonCoordinates.latitude], { units: "metres" })
    },
    best2DResult() {
      return this.distanceToTarget
    },
    best3DResult() {
      var distance = this.distanceToTarget
      var dropAltitude = this.altitudeAfterCorrection - this.targetElevationAfterCorrection
      
      // Pythagoras: c^2 = a^2 + b^2 => c = sqrt(a^2 + b^2)
      return Math.sqrt(Math.pow(distance, 2) + Math.pow(dropAltitude, 2))
    },
    targetElevationAfterCorrection() {
      localStorage.setItem('targetElevationCorrection', this.targetElevationCorrection)

      return this.targetElevation + Number(this.targetElevationCorrection)
    },
    targetElevationAfterCorrectionFeet() {
      return this.targetElevationAfterCorrection * 3.28084
    },
    altitudeAfterCorrection() {
      localStorage.setItem('altitudeCorrection', this.altitudeCorrection)

      return this.geolocation.coords.altitude + Number(this.altitudeCorrection)
    },
    altitudeAfterCorrectionFeet() {
      return this.altitudeAfterCorrection * 3.28084
    },
    avgSpeed() {
      return this.avg(this.recordedSpeeds)
    },
    avgHeading() {
      return this.avg(this.recordedHeadings)
    },
  },
  watch: {
    whenToDrop: function () {
      // play audio when close to drop point  

      this.playAudio(Math.round(this.whenToDrop))

    },
    geolocation: function () {
      // update windreader readings for current altitude
      
      var heading = this.geolocation.coords.heading
      var speed = this.geolocation.coords.speed
      var alt = this.altitudeAfterCorrectionFeet
      var windreaderAlt = Math.round(alt / 100) * 100

      // create row if doesn't exist yet
      if (!this.windreader[windreaderAlt]) {
        this.windreader[windreaderAlt] = {
          headings: [],
          speeds: [],
          avgHeading: 0,
          avgSpeed: 0,
          updatedAt: 0,
        }
      }

      // put at the beginning of array
      this.windreader[windreaderAlt].headings.unshift(heading)
      this.windreader[windreaderAlt].speeds.unshift(speed)

      // reduce array to maximum 20 readings
      this.windreader[windreaderAlt].headings = this.windreader[windreaderAlt].headings.splice(0,20)
      this.windreader[windreaderAlt].speeds = this.windreader[windreaderAlt].speeds.splice(0,20)
      
      // calculate average value of last 20 readings
      this.windreader[windreaderAlt].avgHeading = this.avg(this.windreader[windreaderAlt].headings)
      this.windreader[windreaderAlt].avgSpeed = this.avg(this.windreader[windreaderAlt].speeds)

      this.windreader[windreaderAlt].updatedAt = new Date()

      // save last 20 speed readings
      this.recordedSpeeds.unshift(this.geolocation.coords.speed)
      this.recordedSpeeds = this.recordedSpeeds.splice(0,20)

      // save last 20 heading readings
      this.recordedHeadings.unshift(this.geolocation.coords.heading)
      this.recordedHeadings = this.recordedHeadings.splice(0,20)
    }
  },
  methods: {
    findTargetElevation: function () {
      var MapboxElevation = require('mapbox-elevation')
      var getElevation = MapboxElevation('pk.eyJ1IjoicGF0cnlra2FsaW5vd3NraSIsImEiOiJjano3ZHphc3MwbHZ5M2htbnE2NTFvcmRsIn0.R2fpUu7ZbinXRJmEig39og');

      getElevation([this.targetLatLonCoordinates.longitude, this.targetLatLonCoordinates.latitude], function (err, elevation) { // getElevation([lon,lat])
        app.targetElevation = Math.round(elevation)
      });
    },
    targetElevationCorrectionUp: function () {
      app.targetElevationCorrection += 1
    },
    targetElevationCorrectionDoubleUp: function () {
      app.targetElevationCorrection += 5
    },
    targetElevationCorrectionDown: function () {
      app.targetElevationCorrection -= 1
    },
    targetElevationCorrectionDoubleDown: function () {
      app.targetElevationCorrection -= 5
    },
    targetElevationCorrectionZero: function () {
      app.targetElevationCorrection = 0
    },
    altitudeCorrectionUp: function () {
      app.altitudeCorrection += 1
    },
    altitudeCorrectionDoubleUp: function () {
      app.altitudeCorrection += 5
    },
    altitudeCorrectionDown: function () {
      app.altitudeCorrection -= 1
    },
    altitudeCorrectionDoubleDown: function () {
      app.altitudeCorrection -= 5
    },
    altitudeCorrectionZero: function () {
      app.altitudeCorrection = 0
    },
    playAudio: function (file) {
      var audio = {
        50: require("./audio/50.wav"),
        40: require("./audio/40.wav"),
        30: require("./audio/30.wav"),
        20: require("./audio/20.wav"),
        10: require("./audio/10.wav"),
        5: require("./audio/5.wav"),
        4: require("./audio/4.wav"),
        3: require("./audio/3.wav"),
        2: require("./audio/2.wav"),
        1: require("./audio/1.wav"),
        0: require("./audio/now.wav"),
      }
      // Setup the new Howl.
      const sound = new Howl({
        src: [audio[file]]
      });

      // Play the sound.
      sound.play();
    },
    avg: function (array) {
      return array.reduce((a,b) => a + b, 0) / array.length
    }
  },
  mounted: function () { // when Vue.js instance is initialized (mounted)
    // update current DateTime every 100ms
      setInterval(function () { 
        app.currentTime = new Date()
      }, 100)
  }
})